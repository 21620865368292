import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import FileBase from "react-file-base64";
import { useDispatch } from "react-redux";
import { TextField, Button, Typography, Paper } from "@material-ui/core";
import ChipInput from "material-ui-chip-input";
import useStyles from "./styles";

import { createPost, updatePost } from "../../actions/posts";

const Form = ({ currentId, setCurrentId }) => {
  const [postData, setPostData] = useState({
    title: "",
    message: "",
    tags: [],
    trailer: "",
    video: "",
    cover: "",
    selectedFile: "",
  });
  const post = useSelector((state) =>
    currentId
      ? state.posts.posts.find((message) => message._id === currentId)
      : null
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("profile"));
  const classes = useStyles();

  const clear = () => {
    setCurrentId(0);
    setPostData({
      title: "",
      message: "",
      tags: [],
      trailer: "",
      video: "",
      cover: "",
      selectedFile: "",
    });
  };
  useEffect(() => {
    if (!post?.title) clear();
    if (post) setPostData(post);
  }, [post]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (currentId === 0) {
      dispatch(createPost({ ...postData, name: user?.result?.name }, history));
      clear();
    } else {
      dispatch(
        updatePost(currentId, { ...postData, name: user?.result?.name })
      );
      clear();
    }
  };

  if (!user?.result?.name) {
    return (
      <Paper className={classes.paper} elevation={6}>
        <Typography variant="h6" align="center">
          Bitte logge dich ein um deine eigene Perle zu veröffentlichen oder
          andere zu liken.
        </Typography>
      </Paper>
    );
  }

  const handleAddChip = (tag) => {
    setPostData({ ...postData, tags: [...postData.tags, tag] });
  };

  const handleDeleteChip = (chipToDelete) => {
    setPostData({
      ...postData,
      tags: postData.tags.filter((tag) => tag !== chipToDelete),
    });
  };

  return (
    <Paper className={classes.paper} elevation={6}>
      <form
        autoComplete="off"
        noValidate
        className={`${classes.root} ${classes.form}`}
        onSubmit={handleSubmit}
      >
        <Typography variant="h6">
          {currentId
            ? `"${post?.title}" bearbeiten`
            : "Film oder Serie hinzufügen"}
          .
        </Typography>
        <TextField
          name="title"
          variant="outlined"
          label="Titel"
          fullWidth
          value={postData.title}
          onChange={(e) => setPostData({ ...postData, title: e.target.value })}
        />
        <TextField
          name="message"
          variant="outlined"
          label="Plot"
          fullWidth
          multiline
          rows={4}
          value={postData.message}
          onChange={(e) =>
            setPostData({ ...postData, message: e.target.value })
          }
        />
        <div className={classes.chip}>
          <ChipInput
            name="tags"
            variant="outlined"
            label="Einzelne Tags mit Enter hinzufügen"
            fullWidth
            value={postData.tags}
            onAdd={(chip) => handleAddChip(chip)}
            onDelete={(chip) => handleDeleteChip(chip)}
            className={classes.chipInput}
          />
        </div>
        <TextField
          name="trailer"
          variant="outlined"
          label="Trailer"
          fullWidth
          value={postData.trailer}
          onChange={(e) =>
            setPostData({ ...postData, trailer: e.target.value })
          }
        />
        <TextField
          name="video"
          variant="outlined"
          label="Video"
          fullWidth
          value={postData.video}
          onChange={(e) => setPostData({ ...postData, video: e.target.value })}
        />
        <TextField
          name="cover"
          variant="outlined"
          label="Online Cover"
          fullWidth
          value={postData.cover}
          onChange={(e) => setPostData({ ...postData, cover: e.target.value })}
        />
        <div className={classes.fileInput}>
          <Typography variant="p">Cover hochladen</Typography>
          <FileBase
            type="file"
            multiple={false}
            onDone={({ base64 }) =>
              setPostData({ ...postData, selectedFile: base64 })
            }
          />
        </div>
        <Button
          className={classes.buttonSubmit}
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          fullWidth
        >
          Eintragen
        </Button>
        <Button
          className={classes.buttonReset}
          variant="contained"
          color="secondary"
          size="small"
          onClick={clear}
          fullWidth
        >
          Leeren
        </Button>
      </form>
    </Paper>
  );
};

export default Form;
