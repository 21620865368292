import { makeStyles } from "@material-ui/core/styles";
import { brown } from "@material-ui/core/colors";

export default makeStyles((theme) => ({
  media: {
    borderRadius: "20px",
    objectFit: "cover",
    width: "100%",
    maxHeight: "400px",
  },
  paper: {
    padding: "20px",
    borderRadius: "15px",
    backgroundColor: "#fff4e6",
  },
  card: {
    display: "flex",
    width: "100%",
    backgroundColor: "#fff4e6",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      flexDirection: "column",
    },
  },
  section: {
    borderRadius: "20px",
    margin: "10px",
    flex: 1,
  },
  imageSection: {
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  trailerSection: {
    width: "50%",
    height: "auto",
    margin: "0px 20px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: "100%",
      height: "100%",
    },
  },
  player: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  recommendedPosts: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  loadingPaper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    borderRadius: "15px",
    height: "39vh",
  },
  commentsOuterContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  commentsInnerContainer: {
    height: "200px",
    overflowY: "auto",
    marginRight: "30px",
  },
  brown: {
    color: theme.palette.getContrastText(brown[700]),
    backgroundColor: brown[700],
  },
}));
