import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
  palette: {
    primary: {
      light: "#3e2723",
      main: "#854442",
      dark: "#002884",
      contrastText: "#fff4e6",
    },
    secondary: {
      light: "#ff7961",
      main: "#3c2f2f",
      dark: "#ba000d",
      contrastText: "#fff4e6",
    },
  },
});
