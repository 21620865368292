import { useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";
import moment from "moment";
import {
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Paper,
  Typography,
} from "@material-ui/core";
import { PlayArrow } from "@material-ui/icons";

import { getPost, getPostsBySearch } from "../../actions/posts";
import CommentSection from "./CommentSection";

import useStyles from "./styles";

const PostDetails = () => {
  const classes = useStyles();

  const { post, posts, isLoading } = useSelector((state) => state.posts);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getPost(id));
  }, [id]);

  useEffect(() => {
    if (post) {
      dispatch(
        getPostsBySearch({ search: "none", tags: post?.tags.join(",") })
      );
    }
  }, [post]);

  if (!post) return null;
  const openPost = (_id) => history.push(`/posts/${_id}`);

  if (isLoading) {
    return (
      <Paper elevation={6} className={classes.loadingPaper}>
        <CircularProgress size="7em" />
      </Paper>
    );
  }

  const recommendedPosts = posts.filter(({ _id }) => _id !== post._id);

  return (
    <Paper className={classes.paper} elevation={6}>
      <div className={classes.card}>
        <div className={classes.section}>
          <Typography variant="h3" component="h2">
            {post.title}
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            color="textSecondary"
            component="h2"
          >
            {post.tags.map((tag) => (
              <Link
                to={`/tags/${tag}`}
                style={{ textDecoration: "none", color: "#854442" }}
              >{` #${tag} `}</Link>
            ))}
          </Typography>
          <Typography gutterBottom variant="body1" component="p">
            {post.message}
          </Typography>
          <Typography variant="h6">
            <Link
              to={`/creators/${post.name}`}
              style={{ textDecoration: "none", color: "#854442" }}
            >
              {` ${post.name}`}
            </Link>
          </Typography>
          <Typography variant="body1">
            {moment(post.createdAt).fromNow()}
          </Typography>
          <Divider style={{ margin: "20px 0" }} />
          {/* <Typography variant="body1"><strong>Echtzeit Chat - Kommt bald!</strong></Typography>
          <Divider style={{ margin: '20px 0' }} /> */}
          <CommentSection post={post} />
          <Divider style={{ margin: "20px 0" }} />
        </div>
        {/* <div className={classes.imageSection}>
          <img
            className={classes.media}
            src={
              post.cover ||
              post.selectedFile ||
              "https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
            }
            alt={post.title}
          />
        </div> */}
        <Card className={classes.trailerSection}>
          <div>
            <CardContent className={classes.content}>
              <Typography component="h5" variant="h5">
                {post.title} Trailer
              </Typography>
              <ReactPlayer
                className={classes.player}
                width="100%"
                height="100%"
                url={post.trailer}
                controls="true"
              />
            </CardContent>
          </div>
        </Card>
      </div>
      {recommendedPosts.length && (
        <div className={classes.section}>
          <Typography gutterBottom variant="h5">
            Weitere Perlen:
          </Typography>
          <Divider />
          <div className={classes.recommendedPosts}>
            {recommendedPosts.map(
              ({ title, message, name, likes, selectedFile, _id }) => (
                <div
                  style={{ margin: "20px", cursor: "pointer" }}
                  onClick={() => openPost(_id)}
                  key={_id}
                >
                  <Typography gutterBottom variant="h6">
                    {title}
                  </Typography>
                  <Typography gutterBottom variant="subtitle2">
                    {name}
                  </Typography>
                  <Typography gutterBottom variant="subtitle2">
                    {message}
                  </Typography>
                  <Typography gutterBottom variant="subtitle1">
                    Likes: {likes.length}
                  </Typography>
                  <img src={selectedFile} width="200px" />
                </div>
              )
            )}
          </div>
        </div>
      )}
    </Paper>
  );
};

export default PostDetails;
